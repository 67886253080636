// Credit: https://codepen.io/stefen/pen/woXEqr

import { useState } from 'react';
import './FloatButton.css';
import {Row,Col, Drawer, Form, Input, Button, Alert, Space} from 'antd'
import DailyAndCoachNotes from 'components/shared-components/Notes/DailyAndCoachNotes'
import TradelyticsGPT from 'components/shared-components/AI'

export default function FloatActionButton() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [notesvisible, setNotesVisible] = useState(false);
  const [coachChatvisible, setcoachChatVisible] = useState(false);
  const [KBvisible, setKBVisible] = useState(false);
  const [GPTVisible, setGPTVisible] = useState(false);


  const handleFABClick = () => {
    setIsExpanded(prevState => !prevState);
  };

  const showDailyNotesDrawer = () => {
  	setNotesVisible(true);
    setIsExpanded(false);
    localStorage.setItem('selectedNotesType',3);
  };

  const showCoachChatDrawer = () => {
  	setcoachChatVisible(true);
    setIsExpanded(false);
    localStorage.setItem('selectedNotesType',2);

  };

  const redirectToAddTrades = () => {
    setIsExpanded(false);
  	window.location.href = '/trades/addtrade'
  };

  const redirectToSupport = () => {
  	setKBVisible(true);
    setIsExpanded(false);

  };

   const showAIChat = () => {
    setGPTVisible(true);
    setIsExpanded(false);

  };


  const onClose = () => {
    setNotesVisible(false);
    setcoachChatVisible(false);
    setKBVisible(false);
    setGPTVisible(false);

  };

  return (
    <>
      <div className={`help-button-wrapper  ${isExpanded ? 'expanded' : ''}`} >
        <ul className="help-list">
          <li>	
            <span onClick={showDailyNotesDrawer}>
            		<img src ='/img/FAB-daily-notes-icon.png' className="mr-3" width='22px' height='22px'/>
            </span>
             <span onClick={showDailyNotesDrawer} className="mr-3">
            		Add Daily Notes
            </span>
          </li>
          <li>
            <span onClick={showCoachChatDrawer}>
            		<img src ='/img/FAB-coach-notes-icon.png' className="mr-3" width='22px' height='22px'/>
            </span>
            <span onClick={showCoachChatDrawer} className="mr-3">
            		Add Coach Notes
            </span>
          </li>
           <li>
            <span onClick={redirectToAddTrades}>
            		<img src ='/img/FAB-add-trades-icon.png' className="mr-3" width='22px' height='22px'/>
            </span>
             <span onClick={redirectToAddTrades} className="mr-3">
            		Add Trades
            </span>
          </li>
           <li>
            <span onClick={redirectToSupport}>
            		<img src ='/img/FAB-support-icon.png' className="mr-3" width='22px' height='22px'/>
            		
            </span>
              <span onClick={redirectToSupport}>
            		Support
            </span>

             
          </li>

           <li>
            <span onClick={showAIChat}>
                <img src ='/img/FAB-add-trades-icon.png' className="mr-3" width='35px' height='22px'/>
            </span>
             <span onClick={showAIChat}>
                Tradelytics AI
            </span>
          </li>
        </ul>

        <button className="help-button" onClick={handleFABClick}>
          <span>+</span>
        </button>
      </div>


      <Drawer 
      			title="Notes" 
      			placement="right" 
      			onClose={onClose} 
      			//width={420}
            width={window.innerWidth > 990 ? 500 : window.innerWidth - 0}
                closable={true}
                visible={notesvisible}
                destroyOnClose = {true}
      	>
          <DailyAndCoachNotes
            type="daily"
           />

           <Row>


             <Col span={24} style={{ marginTop: '16px' }}> 
            </Col>

             <Row>
              <Button type="primary" onClick={onClose}>Done</Button>
               <Alert message="The notes section autosaves." type="info" showIcon closable />
              
            </Row>
          </Row>
      </Drawer>

      <Drawer 
      			title="Notes" 
      			placement="right" 
      			onClose={onClose} 
      			//width={420}
            width={window.innerWidth > 990 ? 500 : window.innerWidth - 0}
                closable={true}
                visible={coachChatvisible}
                destroyOnClose = {true}

      	>
         <DailyAndCoachNotes 
            type="coach"
          />

          <Row>
            <Button type="primary" onClick={onClose}>Done</Button> 
            <Alert message="The notes section autosaves." type="info" showIcon closable />

          </Row>

      </Drawer>

      <Drawer 
      			title="Tradelytics Knowledge Base" 
      			placement="right" 
      			onClose={onClose} 
      			//width={420}
            width={window.innerWidth > 900 ? 500 : window.innerWidth - 0}
                closable={true}
                visible={KBvisible}
      	>
          <Row>
					
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
	          <Form layout="vertical">
	            <Form.Item name="KB" label="Tradelytics Knowledge Base">
						 <div>
						 	Knowledge Base Articles.
						 </div>

				</Form.Item>
	          </Form>
          </Col>
          </Row>
      </Drawer>
       <Drawer 
            title="Tradelytics AI - Powered by OpenAI" 
            placement="right" 
            onClose={onClose} 
            //width={420}
            width={window.innerWidth > 900 ? 500 : window.innerWidth - 0}
                closable={true}
                visible={GPTVisible}
        >
          <Row>
          
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form layout="vertical">
              <Form.Item name="AI" label="Analyze Trades with Tradelytics AI">
             <div>
                  <TradelyticsGPT />
             </div>

        </Form.Item>
            </Form>
          </Col>
          </Row>
      </Drawer>
    </>
  );
}
