import fetch from 'auth/FetchInterceptor';
import instrumentedFetch from './InstrumentedFetch';
import { message } from 'antd';
import utils from 'utils';
import { API_BASE_URL } from 'configs/AppConfig'
import { AUTH_TOKEN } from 'redux/constants/Auth'


const UserService = {}

  UserService.getUserAccount = function (params) {
    return new Promise((resolve, reject) => {
        resolve(instrumentedFetch(API_BASE_URL+'user/broker-account'));
    }).then(res => res);
  }

   // New method to submit broker account details
  UserService.saveBrokerAccountDetails = function (params) {
     return new Promise((resolve, reject) => {
      resolve(instrumentedFetch(process.env.REACT_APP_ENDPOINT_TRADES_ADD_AUTOSYNC_ACCOUNT,params,'POST'));       
    }).then(res => res);
  }

  UserService.registerNewUser = function (params) {
     return new Promise((resolve, reject) => {
      resolve(instrumentedFetch(API_BASE_URL+'user/register',params,'POST'));       
    }).then(res => res);
  }

export default UserService