import fetch from 'auth/FetchInterceptor';
import instrumentedFetch from './InstrumentedFetch';
import { message } from 'antd';
import utils from 'utils';
import { API_BASE_URL } from 'configs/AppConfig'
import { AUTH_TOKEN } from 'redux/constants/Auth'


const AIService = {}

  AIService.AnalyzeTrades = function (params) {
    return new Promise((resolve, reject) => {
        resolve(instrumentedFetch(API_BASE_URL + 'ai/analyze-trades'));
    }).then(res => res.analysis);
  }

export default AIService