import React, { useState } from 'react';
import { Input, Button, Card, Skeleton } from 'antd';
import AIService from 'services/AIService';

function TradelyticsGPT() {
  const { TextArea } = Input;
  const [prompt, setPrompt] = useState('Analyze my trades');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  async function handlePromptSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      const analysis = await AIService.AnalyzeTrades();
      console.log(analysis);
      setResponse(analysis.content);
    } catch (error) {
      console.error('Error analyzing trades:', error);
      setResponse('There was an error analyzing your trades.');
    } finally {
      setIsLoading(false);
    }
  }

  function handlePromptChange(event) {
    setPrompt(event.target.value);
  }

  return (
    <>
      <div style={{ padding: '20px', width: '100%'  }}>

        <div style={{ marginBottom: '20px'}}>
          <p><strong>Tradelytics AI</strong> leverages up to 50 of trades based on your filter criteria to deliver actionable insights powered by advanced AI analysis. Only the trades that meet your filter criteria will be included in the review.</p>
          <p>Click <strong>'Start Analysis'</strong> to begin and unlock detailed performance insights.</p>
        </div> 

        <Button type="primary" onClick={handlePromptSubmit}>
          Start Analysis
        </Button>

          <Card
          style={{
            width: '100%', marginTop: '20px',
            border: response ? '1px solid #d9d9d9' : 'none', // Apply border conditionally
            boxShadow: response ? '0 2px 8px rgba(0, 0, 0, 0.1)' : 'none', // Optional shadow when response is available
          }}
        >
          {isLoading ? (
            <div>
              <Skeleton active />
            </div>
          ) : (
            response && (
              <div style={{ width: '100%' }} 
                dangerouslySetInnerHTML={{ __html: convertMarkdownToHTML(response) }}
              />
            )
          )}
        </Card>

        <div style={{ marginTop: '20px', fontStyle: 'italic', width: '100%' }}>
          Disclaimer: The information and analysis provided by Tradelytics AI is for informational purposes only and does not constitute financial advice.
        </div>

      </div>

    </>
  );
}

// Utility function to convert Markdown to HTML
function convertMarkdownToHTML(markdown) {
  // Optionally you can use libraries like marked or showdown
  const markdownToHTML = markdown
    .replace(/### /g, '<h3>') // Headers
    .replace(/#### /g, '<h4>')
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold text
    .replace(/\n/g, '<br>'); // Line breaks

  return markdownToHTML;
}

export default TradelyticsGPT;
